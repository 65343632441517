import Vue from 'vue'
import Vuex from 'vuex'

import outfits from '@/modules/outfits'
import layouts from '@/modules/layouts'
import products from '@/modules/products'
import cart from '@/modules/cart'
import user from '@/modules/user'
import qec from '@/modules/qec'
import payment from '@/modules/payment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaderDerek: false,
    showSizeGuide: false,
    displayBtnHelp: true,
    clickBtnFavorite: false,
    innerWidth: 0,
    esFE: '0',
    showModal423: false,
    dataModal423: {
      title: 'Lo sentimos',
      descripcion: 'Parece que algo salio mal, por favor recarga el sitio.',
      routeName: 'PaymentEmail',
    },
  },
  mutations: {
    setEsFE(state, newVal) {
      state.esFE = newVal ? '1' : '0'
    },
  },
  actions: {
    changeEsFE({ commit }, newVal) {
      commit('setEsFE', newVal)
    },
  },
  modules: {
    layouts,
    products,
    outfits,
    cart,
    user,
    payment,
    qec,
  },
})
