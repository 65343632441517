import qecAxios from '../../plugins/qecAxios'

export async function getCartQEC({ state }, payload) {
  await qecAxios.get(`cart?token=${payload.token}`).then(response => {
    state.dataQEC = response.data.data
  })
}

export async function paymentQECEfecty({ state }, payload) {
  state.errorMessageEfecty = ''
  var form = new FormData()
  form.append('token', payload.token)
  form.append('payment_method', 'efecty')
  await qecAxios
    .post(`cart/payment`, form)
    .then(response => {
      state.dataQEC = response.data.data
      location.href = response.data.data.url
    })
    .catch(err => {
      state.errorMessageEfecty =
        err.response.data.message ?? 'Parece que algo salio mal'
    })
}

export async function paymentQECPSE({ state }, payload) {
  state.errorPaymentPSE = ''
  await qecAxios
    .post(`cart/payment`, {
      token: payload.token,
      payment_method: 'pse',
      payload: payload.payload,
    })
    .then(response => {
      state.dataQEC = response.data.data
      location.href = response.data.data.url
    })
    .catch(err => {
      state.errorPaymentPSE =
        err.response.data.message ?? 'Parece que algo salio mal'
    })
}

export async function validateEmailService({ state }, payload) {
  await qecAxios
    .post(`cart/validate-mail`, {
      token: payload.token,
      email: payload.email,
    })
    .then(response => {
      if (response.data.success) {
        state.dataQEC.cart.client.verified_email = true
        state.dataQEC.cart.client.email = payload.email
      } else {
        state.errorMessageEmail = response.data.message
      }
    })
    .catch(err => {
      state.errorMessageEmail =
        err.response.data.message ?? 'Parece que algo salio mal'
    })
}
