/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/lanzamiento',
  //   name: 'Lanzamiento',
  //   hidden: true,
  //   component: () => import(/* webpackChunkName: "Home" */ '@/views/Launch.vue'),
  //   meta: { requireAuth: false }
  // },
  {
    path: '/',
    name: 'Home',
    hidden: true,
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/qec/checkout/:tokenCart',
    name: 'checkoutQEC',
    beforeEnter: (to, from, next) => {
      document.title = 'DEREK | Checkout QEC'
      next()
    },
    component: () =>
      import(/* webpackChunkName: "checkoutQEC" */ '@/views/qec/Checkout.vue'),
  },
  {
    path: '/regalos',
    name: 'Gifs',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Regalos | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "Gifs" */ '@/views/Gifts.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/bolsa',
    name: 'MyBag',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Bolsa | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "MyBag" */ '@/views/MyBag.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/auth/:tokenCart',
    name: 'PaymentEmailAnonymous',
    hidden: true,
    component: () => import(/* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/PaymentAnonymous.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/profile/:tokenCart',
    name: 'PaymentProfileAnonymous',
    hidden: true,
    component: () => import(/* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/PaymentAnonymous.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/shipping-address/:tokenCart',
    name: 'PaymentShippingAddressAnonymous',
    hidden: true,
    component: () => import(/* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/PaymentAnonymous.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/payment/:tokenCart',
    name: 'PaymentAnonymous',
    hidden: true,
    component: () => import(/* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/PaymentAnonymous.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/summary-payment/:tokenCart',
    name: 'PaymentSummaryAnonymous',
    hidden: true,
    component: () => import(/* webpackChunkName: "PaymentAnonymous" */ /*'@/views/Payment.vue'*/ '@/views/PaymentAnonymous.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout',
    name: 'Payment1',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/auth',
    name: 'PaymentEmail',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/profile',
    name: 'PaymentProfile',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/shipping-address',
    name: 'PaymentShippingAddress',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/payment',
    name: 'Payment',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/checkout/summary-payment',
    name: 'PaymentSummary',
    hidden: true,
    component: () => import(/* webpackChunkName: "Payment" */ /*'@/views/Payment.vue'*/ '@/views/Checkout.vue'),
    meta: { requireAuth: false }
  },
  // {
  //   path: '/pruebas-desarrollo',
  //   name: 'PruebasDesarrollo',
  //   hidden: true,
  //   component: () => import(/* webpackChunkName: "PruebasDesarrollo" */ '@/views/PruebasDesarrollo.vue'),
  //   meta: { requireAuth: false }
  // },
  {
    path: '/infocupo',
    name: 'InfoCupo',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Paga tu cuota | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "InfoCupo" */ '@/views/PayQuota.vue'),
    meta: { requireAuth: false }
  },
  // {
  //   path: '/cupoexpress',
  //   name: 'CupoExpress',
  //   hidden: true,
  //   component: () => import(/* webpackChunkName: "CupoExpress" */ '@/views/CupoExpress.vue'),
  //   beforeEnter: (to, from, next) => {
  //     document.title = 'Cupo Personal | DEREK Tienda Online';
  //     next();
  //   },
  //   meta: { requireAuth: false }
  // },
  // {
  //   path: '/trendy',
  //   name: 'Trendy',
  //   hidden: true,
  //   component: () => import(/* webpackChunkName: "Trendy" */ '@/views/Trendy.vue'),
  //   meta: { requireAuth: false }
  // },
  {
    path: '/perfil',
    name: 'Profile',
    hidden: true,
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
    meta: { requireAuth: false },
  },
  {
    path: '/perfil-mis-pedidos',
    name: 'ProfileOrders',
    hidden: true,
    component: () => import(/* webpackChunkName: "ProfileOrders" */ '@/views/ProfileMisPedidos.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/perfil-mis-direcciones',
    name: 'ProfileAddress',
    hidden: true,
    component: () => import(/* webpackChunkName: "ProfileAddress" */ '@/views/ProfileMisDirecciones.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/perfil-cambiar-clave',
    name: 'CambiarClave',
    hidden: true,
    component: () => import(/* webpackChunkName: "CambiarClave" */ '@/views/ProfileClave.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/perfil-mis-favoritos',
    name: 'PerfilFavorites',
    hidden: true,
    component: () => import(/* webpackChunkName: "PerfilFavorites" */ '@/views/ProfileMisFavoritos.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/perfil-mi-credito',
    name: 'PerfilCredit',
    hidden: true,
    component: () => import(/* webpackChunkName: "PerfilCredit" */ '@/views/ProfileMiCredito.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/seguimiento-de-pedido',
    name: 'FollowOrder',
    hidden: true,
    beforeEnter: (to, from, next) => {
      document.title = 'Seguimiento de pedido | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "FollowOrder" */ '@/views/FollowOrder.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/respuesta-addi/:referenceCode',
    name: 'PaymentAddy',
    beforeEnter: (to, from, next) => {
      document.title = 'DEREK | Respuesta checkout addi'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuota" */ '@/views/OrderDetailAddi.vue'
      ),
  },
  {
    path: '/respuesta-quac/:referenceCode',
    name: 'PaymentQuac',
    beforeEnter: (to, from, next) => {
      document.title = 'DEREK | Respuesta checkout quac'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuac" */ '@/views/OrderDetailQuac.vue'
      ),
  },
  {
    path: '/respuesta-sistecredito/:referenceCode',
    name: 'PaymentSistecredito',
    beforeEnter: (to, from, next) => {
      document.title = 'DEREK | Respuesta checkout sistecredito'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentSistecredito" */ '@/views/OrderDetailSistecredito.vue'
      ),
  },
  {
    path: '/pedido-detalle',
    name: 'PedidoDetalle',
    beforeEnter: (to, from, next) => {
      document.title = 'Detalle de pedido | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "PedidoDetalle" */ '@/views/OrderDetail.vue'),
  },
  {
    path: '/devoluciones',
    name: 'Returns',
    hidden: true,
    component: () => import(/* webpackChunkName: "Returns" */ '@/views/Returns.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/tiendas',
    name: 'Tiendas',
    hidden: true,
    component: () => import(/* webpackChunkName: "Tiendas" */ '@/views/Tiendas.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/preguntas-frecuentes',
    name: 'Preguntasfrecuentes',
    hidden: true,
    component: () => import(/* webpackChunkName: "Preguntasfrecuentes" */ '@/views/FrecuentQuestions.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/franquicias',
    name: 'Franquicia',
    hidden: true,
    component: () => import(/* webpackChunkName: "Franquicia" */ '@/views/Franquicia.vue'),
    meta: { requireAuth: false },
    beforeEnter: (to, from, next) => {
      next({name: 'Home'});
      document.title = 'Franquicias | DEREK Tienda Online';
    },
  },
  {
    path: '/estadocuenta/:token',
    name: 'EstadoCuenta',
    beforeEnter: (to, from, next) => {
      document.title = 'Estado de cuenta | DEREK Tienda Online'
      next()
    },
    component: () => import(/* webpackChunkName: "EstadoCuenta" */ '@/views/EstadoCuenta.vue')
  },
  {
    path: '/politica-:slug',
    name: 'Politicas',
    component: () => import(/* webpackChunkName: "HabeasData" */ '@/views/PrivacyPolicies.vue'),
  },
  {
    path: '/sku/:sku',
    name: 'ProductDetailSku',
    hidden: true,
    component: () => import(/* webpackChunkName: "ProductDetail" */ '@/views/ProductDetail.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/comprar/:cartId-:token',
    name: 'Comprar',
    component: () => import(/* webpackChunkName: "Comprar" */ '@/views/Comprar.vue')
  },
  {
    path: '/guia-de-tallas',
    name: 'Sizeguide',
    hidden: true,
    component: () => import(/* webpackChunkName: "Sizeguide" */ '@/views/SizeGuide.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/product-:url/agregar-valoracion',
    name: 'AddComment',
    beforeEnter: (to, from, next) => {
      document.title = 'Valoración de producto | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "AddComment" */ '@/views/ProductEvaluation.vue')
  },
  // {
  //   path: '/trendy-:slug',
  //   name: 'Trendydetail',
  //   hidden: true,
  //   component: () => import(/* webpackChunkName: "Trendydetail" */ '@/views/TrendyDetail.vue'),
  //   meta: { requireAuth: false }
  // },
  {
    path: '/campaigns-:category-:id',
    name: 'OutfitDetail',
    hidden: true,
    component: () => import(/* webpackChunkName: "OutfitDetail" */ '@/views/OutfitDetail.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/campaigns-:category',
    name: 'OutfitsCategory',
    hidden: true,
    component: () => import(/* webpackChunkName: "OutfitsCategory" */ '@/views/Outfit.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/campaigns',
    name: 'Outfits',
    hidden: true,
    component: () => import(/* webpackChunkName: "Outfits" */ '@/views/Outfit.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/respuesta-bancolombia',
    name: 'PaymentQuota',
    beforeEnter: (to, from, next) => {
      document.title = 'Resumen de compra | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "PaymentQuota" */ '@/views/PaymentQuota.vue')
  },
  {
    path: '/respuesta-bancolombia-pedido',
    name: 'PaymentQuota',
    beforeEnter: (to, from, next) => {
      document.title = 'DEREK | Respuesta checkout'
      next()
    },
    component: () =>
      import(
        /* webpackChunkName: "PaymentQuota" */ '@/views/OrderDetailBancolombia.vue'
      ),
  },
  {
    path: '/abono-cupo-detalle',
    name: 'PaymentQuotaDetail',
    component: () => import(/* webpackChunkName: "PaymentQuotaDetail" */ '@/views/PaymentQuotaDetail.vue'),
  },
  {
    path: '/product-:product',
    name: 'ProductDetail',
    hidden: true,
    component: () => import(/* webpackChunkName: "ProductDetail" */ '@/views/ProductDetail.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/store',
    name: 'Store',
    hidden: true,
    component: () => import(/* webpackChunkName: "Store" */ '@/views/StoreDerek.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/:category-:subcategory-:wildcard',
    name: 'TiendaWildcard',
    hidden: true,
    component: () => import(/* webpackChunkName: "TiendaSubcategory" */ '@/views/StoreDerek.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/:category-:subcategory',
    name: 'TiendaSubcategory',
    hidden: true,
    component: () => import(/* webpackChunkName: "TiendaSubcategory" */ '@/views/StoreDerek.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/:category',
    name: 'TiendaCategory',
    hidden: true,
    component: () => import(/* webpackChunkName: "TiendaCategory" */ '@/views/StoreDerek.vue'),
    meta: { requireAuth: false }
  },
  {
    path: ':path',
    name: 'Error',
    beforeEnter: (to, from, next) => {
      document.title = 'Parece que no encontramos la página | DEREK Tienda Online';
      next();
    },
    component: () => import(/* webpackChunkName: "Error" */ '@/views/Page404.vue'),
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      document.title = 'Parece que no encontramos la página | DEREK Tienda Online';
      next();
    },
    component: () =>
      import(/* webpackChunkName: "Error" */ '@/views/Page404.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  async scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.path == from.path) {
        return {}
      } else {
        return { x: 0, y: 0, behavior: 'auto' }
      }
    }
  },
})

// Vue.use(VueGtag, {
//   includes: [
//     { id: "UA-66959020-1" },
//   ],
//   config: { id: "G-L8XG5NN0XL" },
// }, router)

export default router
